<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>Users Daily Statistics</h4>
        </v-col>
      </v-layout>

      <v-layout wrap>
        <v-col cols="12" md="6">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="Select a Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-menu>
        </v-col>
        <v-col cols="12" md="6">
            <v-layout >
                <v-col cols="6" align-self="left">
                     <v-btn elevation="2" @click="fetchUsers">Filter</v-btn>
                </v-col>
                 <v-col cols="6" align-self="right">
                    <v-btn
                    :loading="loading5"
                    color="blue-grey"
                    class="white--text"
                    @click="exportToPDF"
                    >
                    <v-icon dark>
                        mdi-cloud-upload
                    </v-icon>
                    </v-btn>
                </v-col>
            </v-layout>
           
        </v-col>
      </v-layout>
      
      <VueHtml2pdf
        :show-layout="false"
        :enable-download="true"
        :paginate-elements-by-height="1400"
        :filename="'qikpharma_'+date"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        ref="html2Pdf"
    >
        <v-row slot="pdf-content">
            <v-col cols="12">
                <v-layout>
                    <v-col cols="12" align="center">
                        <h5> {{date}} qikPharma User Report</h5>
                    </v-col>
                </v-layout>
                <v-layout wrap class="mt-3" >
                    <v-col cols="6" md="2">
                        <v-layout class="boxStat" style="border: 1px solid #ffd809;">
                            <v-col cols="12">
                            <v-layout>
                                <v-col cols="12">
                                <span>Manufacturers</span>
                                </v-col>
                            </v-layout>
                            <v-layout>
                                <v-col cols="12"> 
                                <span class="text-h5"><b>{{Manufacturers.length}}</b></span>
                                </v-col>
                            </v-layout>
                            </v-col>
                        </v-layout>
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                            <v-col cols="12">
                            <v-layout>
                                <v-col cols="12">
                                <span>Distributors</span>
                                </v-col>
                            </v-layout>
                            <v-layout>
                                <v-col cols="12">
                                <span class="text-h5"><b>{{Distributors.length}}</b></span>
                                </v-col>
                            </v-layout>
                            </v-col>
                        </v-layout>
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-layout class="boxStat" style="border: 1px solid #2776ED;">
                            <v-col cols="12">
                            <v-layout>
                                <v-col cols="12">
                                <span>Retailers</span>
                                </v-col>
                            </v-layout>
                            <v-layout>
                                <v-col cols="12">
                                <span class="text-h5"><b>{{Retailers.length}}</b></span>
                                </v-col>
                            </v-layout>
                            </v-col>
                        </v-layout>
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-layout class="boxStat" style="border: 1px solid #2776ED;">
                            <v-col cols="12">
                            <v-layout>
                                <v-col cols="12">
                                <span>Professionals</span>
                                </v-col>
                            </v-layout>
                            <v-layout>
                                <v-col cols="12">
                                <span class="text-h5"><b>{{Professionals.length}}</b></span>
                                </v-col>
                            </v-layout>
                            </v-col>
                        </v-layout>
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                            <v-col cols="12">
                            <v-layout>
                                <v-col cols="12">
                                <span>Users</span>
                                </v-col>
                            </v-layout>
                            <v-layout>
                                <v-col cols="12">
                                <span class="text-h5"><b>{{Users.length}}</b></span>
                                </v-col>
                            </v-layout>
                            </v-col>
                        </v-layout>
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                            <v-col cols="12">
                            <v-layout>
                                <v-col cols="12">
                                <span>Logistics</span>
                                </v-col>
                            </v-layout>
                            <v-layout>
                                <v-col cols="12">
                                <span class="text-h5"><b>{{logisticsCompanyData.length}}</b></span>
                                </v-col>
                            </v-layout>
                            </v-col>
                        </v-layout>
                    </v-col>
                </v-layout>

                <v-layout wrap class="whiteBg" v-if="userAccounts.length >= 1">
            <v-col cols="12">
              <v-layout wrap>
                <h5>Users Details</h5>
              </v-layout>

              
              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="auto">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Email
                          </th>
                          <th class="text-left">
                            Role
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in userAccounts" :key="user.userId">
                          <td style="font-size:12px;">
                              {{user.name}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.email}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.Role.name}}
                          </td>
                        
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
            </v-col>
        </v-row>
      </VueHtml2pdf>
      
      <v-layout>
        <v-col cols="12" align-self="center">
            <h5>{{date}} User Report</h5>
        </v-col>
      </v-layout>

      <v-layout wrap class="mt-3">
                <v-col cols="6" md="2">
                  <v-layout class="boxStat" style="border: 1px solid #ffd809;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span>Manufacturers</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="12"> 
                          <span class="text-h5"><b>{{Manufacturers.length}}</b></span>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="6" md="2">
                  <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span>Distributors</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="12">
                          <span class="text-h5"><b>{{Distributors.length}}</b></span>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="6" md="2">
                  <v-layout class="boxStat" style="border: 1px solid #2776ED;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span>Retailers</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="12">
                          <span class="text-h5"><b>{{Retailers.length}}</b></span>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="6" md="2">
                  <v-layout class="boxStat" style="border: 1px solid #2776ED;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span>Professionals</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="12">
                          <span class="text-h5 ml-5"><b>{{Professionals.length}}</b></span>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="6" md="2">
                  <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span>Users</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="12">
                          <span class="text-h5"><b>{{Users.length}}</b></span>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

                <v-col cols="6" md="2">
                  <v-layout class="boxStat" style="border: 1px solid #72BD61;">
                    <v-col cols="12">
                      <v-layout>
                        <v-col cols="12">
                          <span>Logistics</span>
                        </v-col>
                      </v-layout>
                      <v-layout>
                        <v-col cols="12">
                          <span class="text-h5"><b>{{logisticsCompanyData.length}}</b></span>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-layout>
                </v-col>

      </v-layout>

      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap class="whiteBg" v-if="userAccounts.length >= 1">
            <v-col cols="12">
              <v-layout wrap>
                <h5>Users Details</h5>
              </v-layout>

              
              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Email
                          </th>
                          <th class="text-left">
                            Role
                          </th>
                          <th class="text-left">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in userAccounts" :key="user.userId">

                          <td style="font-size:12px;">
                              {{user.name}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.email}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.Role.name}}
                          </td>
                        
                          <td style="font-size:12px;">
                              <span v-if="user.isActive"  style="color:green;"> Approved</span> <span style="color:red;" v-else> Not Approved</span> 
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

    </v-col>
  </v-row>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment';
import AdminMenu from "../others/AdminMenu";

export default {
  name: "AdminUserStatistic",
  components: {
    AdminMenu,
    VueHtml2pdf
  },
  data() {
    return {
      loader:false,
      userAccounts: [],
      Users:[],
      loading5: false,
      Manufacturers: [],
      Distributors: [],
      Retailers: [],
      Professionals: [],
      logisticsCompanyData: [],
      roles: [],
      user:{},
      systemSetting: {
        systemCurrency: "₦",
      },
      extraDetail:{},
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
    };
  },
  watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 5000)

        this.loader = null
      },
    },
  created() {
    this.fetchUsers();
    this.getLogisticsCompanies();
  },
  methods: {
    fetchUsers(){
        this.Manufacturers = [];
        this.Users = [];
        this.Retailers = [];
        this.Distributors = [];
        this.Professionals = [];
        this.userAccounts = [];
        this.$http
        .get(`${process.env.VUE_APP_URL}users`)
        .then((response) => {
        
          response.data.data.forEach(element => {
            if(moment(element.createdAt).format('YYYY-MM-DD') == this.date)
            {
                this.userAccounts.push(element);
                if(element.Role.name == 'Manufacturer')
                {
                     this.Manufacturers.push(element);
                }
                if(element.Role.name == 'Distributor')
                {
                     this.Distributors.push(element);
                } 
                if(element.Role.name == 'Retailer')
                {
                     this.Retailers.push(element);
                } 
                if(element.Role.name == 'Professional')
                {
                    this.Professionals.push(element);
                }
                if(element.Role.name == 'User')
                {
                     this.Users.push(element);
                }
               

             
            }
          });
        });
    },
    getLogisticsCompanies() {
      this.$http
        .get(`${process.env.VUE_APP_URL}logistics`)
        .then((response) => {
          response.data.data.forEach(element => {
            if(moment(element.createdAt).format('YYYY-MM-DD') == this.date)
            {
                this.logisticsCompanyData.push(element);
            }
          });
        });
    },
    exportToPDF() {
        this.loader = 'loading5';
        this.$refs.html2Pdf.generatePdf();
    },

  },
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1px;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
